<template>
  <div class="ui-button-upload">
    <new-button
      class="ui-button-upload__button"
      type="ghost"
      size="sm"
      :action="action"
      :disabled="disabled"
      @click="handlePickFile"
    >
      {{ title }}
      <ui-icon name="plus-rounded" size="24" />
    </new-button>
    <input
      v-bind="$attrs"
      ref="input-upload"
      class="ui-button-upload__input"
      type="file"
      :multiple="multiple"
      :accept="acceptFiles"
      :disabled="disabled"
      @change="handleFilePicked"
    />
  </div>
</template>

<script>
import NewButton from '@/components/UI/NewButton.vue';

export default {
  name: 'ButtonUpload',

  components: {
    NewButton,
  },

  props: {
    title: String,
    acceptFiles: String,
    action: Boolean,
    multiple: Boolean,
    disabled: Boolean,
  },

  methods: {
    handlePickFile() {
      this.$refs['input-upload'].value = '';
      this.$refs['input-upload'].click();
    },
    handleFilePicked(event) {
      this.$emit('upload', event);
    },
  },
};
</script>
